<template>
  <div class="p-5 md:px-12">
    <v-calendar
        class="custom-calendar max-w-full"
        v-bind="calendarAttributes">

      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 justify-between overflow-hidden">
          <span class="day-label text-lg font-bold" :class="attributes?.length ? 'text-gray-type-2': 'text-gray-type-6'">{{ day.day }}</span>
          <div class="flex sm:block overflow-y-auto overflow-x-auto">
            <p
                v-for="attr in attributes"
                :key="attr.key"
                class="vc-event-badge"
                :class="attr.customData.class">
              <span class="text">{{ attr.customData.title }}</span>
            </p>
          </div>
        </div>
      </template>
    </v-calendar>
  </div>
</template>

<script>
export default {
  name: "StaffTripCalendar",
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    attendanceData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      calendarAttributes: {
        isExpanded: true,
        titlePosition: 'left',
        minDate: this.data.startDate,
        maxDate: this.data.endDate,
        disablePageSwipe: true,
        masks: {
          weekdays: 'WWW',
        },
        attributes: [],
      },
    }
  },
  mounted() {
    this.processAttendanceData(this.attendanceData);
  },
  methods : {
    updateDefaultConfig(data, attributes = null){
      if(attributes) {
        this.calendarAttributes = {...this.calendarAttributes, minDate: data.startDate, maxDate: data.endDate, attributes};
      } else {
        this.calendarAttributes = {...this.calendarAttributes, minDate: data.startDate, maxDate: data.endDate};
      }
    },
    processAttendanceData(staffRouteTripDetails){
      const attributes = [];
      if(staffRouteTripDetails.attendance.length) {
        staffRouteTripDetails.attendance.forEach(item => {
          const date = new Date(item.date);
          if(item.morning) {
            attributes.push(this.createAttendanceData(`${item.date}-1`, 'morning', 'Morning',date));
          }
          if(item.evening) {
            attributes.push(this.createAttendanceData(`${item.date}-2`, 'evening', 'Evening',date));
          }
        });
        this.updateDefaultConfig({...this.data}, attributes);
      }
    },
    createAttendanceData(key, className, title, date) {
      return {
        key,
        customData: {
          title,
          class: className
        },
        dates: date
      }
    },
    initializeCalendarView() {

    }
  }

}
</script>

<style lang="scss" scoped>
:deep .custom-calendar.vc-container {

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  --day-border: .5px solid theme('colors.gray.type-7');
  --day-width: 90px;
  --day-height: 83px;
  --weekday-bg: #f8fafc;
  @apply border border-solid border-gray-type-7;
  border-radius: 0.3125rem;
  width: 100%;
  overflow-x: auto;

  .vc-pane-container {
    border-radius:  inherit;
    border: 1px solid transparent;
  }

  & .vc-header {
    @apply bg-white;
    padding: 1.6875rem;
    pointer-events: none;

    .vc-title {
      font-weight: bold;
      font-size: 2rem;
      line-height: 2.875rem;
      @apply text-gray-type-15;
    }
  }
  & .vc-weeks {
      padding: 0;
  }
  & .vc-weekday {
    padding: 5px 0;
    @apply font-bold text-gray-type-16;
    border: var(--day-border);
    &:first-of-type, &:last-of-type {
      border-left: none;
      border-right: none;
    }
  }

  & .vc-event-badge {
    @apply text-xxs font-medium leading-tight rounded-xl
    py-0.5 px-1.5 mt-0 mb-1;
    width: max-content;

    &.morning {
      @apply bg-indigo-100 text-indigo-500;
    }

    &.evening {
      @apply bg-green-100 text-green-500;
    }
  }
  & .vc-day {
    padding: .5rem;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    .vc-day-content {
      opacity: .4;
    }

    &.in-next-month,
    &.in-prev-month {
      &.on-bottom {
        visibility: hidden;
        height: 0;
        margin: 0;
        padding: 0;
        width: 0;
        min-width: 0;
        min-height: 0;
      }
    }

    &.vc-day-box-center-center{
      justify-content: flex-start;
      align-items: flex-start;
    }

    &:not(.weekday-7) {
      border-right: var(--day-border);
    }

    &:not(.on-bottom) {
      border-bottom: var(--day-border);
    }
  }

  & .vc-day-dots {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 640px) {
    --day-width: 45px;
    --day-height: 45px;
    & .vc-day {
      padding: 0.125rem;

      .vc-event-badge{
        @apply h-2 w-2 rounded-2xl p-0;
        .text {
          display: none;
        }

        &.morning {
          @apply bg-indigo-500;
        }

        &.evening {
          @apply bg-green-500;
        }
      }
    }
  }
}
</style>